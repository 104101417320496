<template>
  <div class="main-container">
    <!-- 导航栏 -->
    <div class="navigation-bar">
      <el-button class="back-btn" icon="el-icon-back" @click="back"
        >返回</el-button
      >
      <el-breadcrumb style="margin-left: 16px" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/#/riskHomeManagement">风险管理</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>工时分布</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 筛选 -->
    <div class="filter">
      <el-form :inline="true" :model="searchParams" class="demo-form-inline">
        <el-form-item label="日期" style="margin-left: 51px">
          <div>
            <el-date-picker
              v-model="searchParams.date"
              type="date"
              prefix-icon="-"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-button-group style="margin-left: 8px">
              <el-button
                @click="nextDay(-1)"
                icon="el-icon-arrow-left"
              ></el-button>
              <el-button @click="nextDay(1)" :disabled="!canNextDay"
                ><i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
          </div>
        </el-form-item>
        <el-form-item label="机械编号" style="margin-left: 51px">
          <el-input
            v-model="searchParams.machineNo"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型" style="margin-left: 51px">
          <el-select
            v-model="searchParams.deviceTypeName"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.deviceTypeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称" style="margin-left: 51px">
          <el-select
            v-model="searchParams.projectId"
            clearable
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in dictList.projectList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey +''"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" style="margin-left: 51px">
          <el-select
            v-model="searchParams.userId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.customerList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="getData"> 查询</el-button
        ><el-button @click="resetData"> 重置</el-button>
      </div>
    </div>
    <!-- 表格/图表 -->
    <div class="table">
      <div class="table-action">
        <div>
          <el-button @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <div class="flex align-center">
          <el-radio-group
            @input="changeShowType"
            v-model="showType"
            size="small"
          >
            <el-radio-button label="list">
              <span><i class="el-icon-s-operation"></i> 列表</span>
            </el-radio-button>
            <el-radio-button label="chart"
              ><span><i class="el-icon-s-data"></i> 图表</span></el-radio-button
            >
          </el-radio-group>
          <div @click="handleExportData" class="export">导出</div>
        </div>
      </div>
      <el-table
        v-show="showType === 'list'"
        :data="tableData"
        style="width: 100%; height: max-content"
        :row-class-name="tableRowClassName"
        max-height="580"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="machineNo" label="机械编号">
          ><template slot-scope="{ row }">{{
            row.machineNo || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称">
          <template slot-scope="{ row }">{{
            row.deviceName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="deviceTypeName" label="设备类型"
          ><template slot-scope="{ row }">{{
            row.deviceTypeName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="daylightWorkingTimeStr" label="白天作业工时"
          ><template slot-scope="{ row }">{{
            row.daylightWorkingTimeStr || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="nightWorkingTimeStr" label="夜间作业工时"
          ><template slot-scope="{ row }">{{
            row.nightWorkingTimeStr || "---"
          }}</template></el-table-column
        >
        <el-table-column prop="sumWorkingTimeStr" label="总工时"
          ><template slot-scope="{ row }">{{
            row.sumWorkingTimeStr || "---"
          }}</template></el-table-column
        >
        <el-table-column prop="customerName" label="客户名称"
          ><template slot-scope="{ row }">{{
            row.customerName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="groupName" label="所属车组"
          ><template slot-scope="{ row }">{{
            row.groupName || "---"
          }}</template></el-table-column
        >
        <el-table-column prop="projectName" label="项目名称"
          ><template slot-scope="{ row }">{{
            row.projectName || "---"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }"
            ><el-button @click="handleWorkTimesDetails(row)" type="text"
              >工时详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div v-show="showType === 'chart'" class="chart" id="chart">
        <!-- <Chart :option="chartOption"></Chart> -->
        <div style="position: relative">
          <div
            style="
              display: flex;
              justify-content: center;
              margin-bottom: 26px;
              position: sticky;
              top: 0;
            "
          >
            <div
              style="display: flex; margin-left: 32px"
              v-for="(item, index) in statuTypes" :key="index"
            >
              <div
                @click="ganttChange(index)"
                :style="{
                  width: '14px',
                  height: '14px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  backgroundColor: item.showBar ? item.color : '#cccccc',
                }"
              ></div>
              <span style="margin-left: 5px">{{ item.title }}</span>
            </div>
          </div>
          <!-- <div id="ganttLable" ref="gantt"></div> -->
          <div id="gantt" ref="gantt" class="gantt"></div>
        </div>
      </div>
      <div class="pagination">
        <div class="total">共计{{ searchParams.total || 0 }}条记录</div>
        <el-pagination
          background
          layout="sizes,prev,pager,next,jumper"
          :total="searchParams.total"
          :current-page.sync="searchParams.index"
          :page-size="searchParams.size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="工时明细" :visible.sync="dialogVisible">
      <el-table :data="detailData">
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <div
              class="status-types"
              :style="{ color: statuTypes[row.status].color }"
            >
              <div
                class="point"
                :style="{ backgroundColor: statuTypes[row.status].color }"
              ></div>
              {{ statuTypes[row.status].title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="statusBegin" label="开始时间"></el-table-column>
        <el-table-column prop="statusEnd" label="结束时间"></el-table-column>
        <el-table-column prop="durationStr" label="累计时长"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getWorkTimesList,
  getWorkTimesChart,
  getWorkTimesDetails,
  getProjectCustomer,
  exportWorkTimes,
} from "@/api/riskData.js";
import { dictionaryBatch } from "@/api/policy";
import { userProject } from "@/api/SystemData";
import Chart from "@/components/Chart/Charts";
import dayjs from "dayjs";
//import * as echarts from "echarts";
import * as d3 from "d3";
export default {
  components: { Chart },
  data() {
    return {
      dialogVisible: false,
      detailData: [],
      dictList: {
        customerList: [],
        deviceTypeList: [],
        projectList: [],
      },
      tableData: [],
      chartData: [],
      exportData: [],
      // list chart
      showType: "list",
      chartOption: {},
      searchParams: {
        index: 1,
        size: 10,
        total: 0,
        date: "",
        machineNo: "",
        deviceTypeName: "",
        projectId: "",
        userId: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return dayjs(time).diff(dayjs(), "day", true) > 0;
        },
      },
      statuTypes: {
        0: { title: "怠速", color: "#FDA835", showBar: true },
        1: { title: "行走", color: "" },
        2: { title: "作业", color: "#0081FF", showBar: true },
        3: { title: "通电", color: "" },
        4: { title: "熄火", color: "" },
        5: { title: "加油", color: "" },
        6: { title: "异常（其它）", color: "" },
      },
    };
  },
  mounted() {},
  created() {
    this.init();
    this.getData();
  },
  computed: {
    canNextDay() {
      //console.log(dayjs().diff(this.searchParams.date, "day") > 0);
      return dayjs().diff(this.searchParams.date, "day") > 0;
    },
  },
  methods: {
    gantt(chartData) {
      d3.select("#gantt").selectChildren("svg").remove();
      const nowDate = dayjs().format("YYYY-MM-DD");
      let data = chartData;
      let myGroups = Array.from(
        new Set(data.map((d) => `${d.machineNo}-${d.deviceTypeName}`))
      );
      let devData = [];
      data.forEach((item) => {
        if (item.workHourDetails && item.workHourDetails.length) {
          item.workHourDetails.forEach((t) => {
            if (this.statuTypes[t.status].showBar) {
              devData.push({
                ...t,
                machineNo: `${item.machineNo}-${item.deviceTypeName}`,
              });
            }
          });
        }
      });
      data = devData;
      let itemHeight = 100;
      let totalHeight = myGroups.length * itemHeight;
      const margin = {
          top: 10,
          right: 30,
          bottom: 30,
          left: d3.max(
            myGroups.map((item) => {
              return item.length * 8 || 0;
            }) || 30
          ),
        },
        width = 1800 - margin.left - margin.right,
        height = totalHeight - margin.top - margin.bottom;
      const svg = d3
        .select("#gantt")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const x = d3.scaleTime(
        [
          dayjs(`${nowDate} 00:00:00`),
          dayjs(`${nowDate} 00:00:00`).add(1, "day"),
        ],
        [0, width]
      );
      const y = d3
        .scaleBand()
        .domain(myGroups)
        .range([0, height])
        .paddingOuter(0.5)
        .paddingInner(1);
      const axisX = d3.axisTop(x).ticks(24, "%H:%M").tickSize(-height);
      svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${margin.top})`)
        .call(axisX)
        .select(".domain")
        .remove();
      svg
        .selectAll(".tick line")
        .attr("stroke", "rgba(0,0,0,0.1)")
        .style("stroke-dasharray", "2,2");
      svg
        .append("g")
        .attr("transform", `translate(0, ${margin.top})`)
        .call(d3.axisLeft(y))
        .select(".domain")
        .remove();

      svg
        .append("g")
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", (d) => {
          return this.statuTypes[d.status || 0].color || "#cccccc";
        })
        .attr("x", (d) => {
          return x(dayjs(`${nowDate} ${d.statusBeginShort}`));
        })
        .attr("y", (d) => {
          return y(d.machineNo);
        })
        .transition()
        .duration(1000)
        .attr("width", (d) => {
          return (
            x(dayjs(`${nowDate} ${d.statusEndShort}`)) -
            x(dayjs(`${nowDate} ${d.statusBeginShort}`))
          );
        })
        .attr("height", 20);
    },
    init() {
      this.searchParams.date = dayjs().format("YYYY-MM-DD");
      this.searchParams = { ...this.searchParams, ...this.$route.query };
      this.getDictionaryBatch();
    },
    back() {
      this.$router.back();
    },
    handleSelectionChange(data) {
      this.exportData = [];
      data.forEach((item) => {
        this.exportData.push(item.machineNo);
      });
    },
    handleWorkTimesDetails(item) {
      getWorkTimesDetails({
        machineNo: item.machineNo,
        date: this.searchParams.date,
      }).then((res) => {
        this.detailData = res.data;
        this.dialogVisible = true;
      });
    },
    handleExportData() {
      if (!this.exportData.length) {
        this.$message({
          message: "请勾选设备！",
          type: "warning",
        });
        return;
      }
      exportWorkTimes({
        machineNos: this.exportData,
        ...this.searchParams,
      }).then(() => {
        this.exportData = [];
      });
    },
    handleSizeChange(e) {
      this.searchParams.size = e;
      this.getData();
    },
    handleCurrentChange(e) {
      this.searchParams.index = e;
      this.getData();
    },
    getData() {
      this.searchParams.date &&
        (this.searchParams.date = dayjs(this.searchParams.date).format(
          "YYYY-MM-DD"
        ));
      getWorkTimesList(this.searchParams).then((res) => {
        this.tableData = res.data.records;
        this.searchParams.total = res.data.total;
      });
      getWorkTimesChart(this.searchParams).then((res) => {
        // res.data.records = [
        //   {
        //     deviceName: "粤E25307",
        //     deviceTypeName: "搬运车",
        //     machineNo: "LZGCR2R63KX027623",
        //     groupName: "AEB车辆",
        //     workHourDetails: [
        //       {
        //         status: 2,
        //         statusStr: "作业",
        //         statusBegin: "2024-05-07 06:45:43",
        //         statusEnd: "2024-05-07 09:14:32",
        //         statusBeginShort: "06:45:43",
        //         statusEndShort: "09:14:32",
        //         duration: 8929,
        //         durationStr: "02小时28分",
        //       },
        //     ],
        //   },
        //   {
        //     deviceName: "粤E25307",
        //     deviceTypeName: "搬运车21312",
        //     machineNo: "LZGCR2R63KX027",
        //     groupName: "AEB车辆",
        //     workHourDetails: [
        //     {
        //         status: 2,
        //         statusStr: "作业",
        //         statusBegin: "2024-05-07 09:14:32",
        //         statusEnd: "2024-05-07 13:14:32",
        //         statusBeginShort: "09:14:32",
        //         statusEndShort: "13:14:32",
        //         duration: 8929,
        //         durationStr: "02小时28分",
        //       },
        //       {
        //         status: 0,
        //         statusStr: "作业",
        //         statusBegin: "2024-05-07 06:45:43",
        //         statusEnd: "2024-05-07 09:14:32",
        //         statusBeginShort: "06:45:43",
        //         statusEndShort: "09:14:32",
        //         duration: 8929,
        //         durationStr: "02小时28分",
        //       },
        //     ],
        //   },
        // ];
        this.chartData = res.data.records;
        this.gantt(res.data.records);
        //this.setChartOption();
      });
    },
    changeShowType(e) {
      if (e === "chart") {
        //this.setChartOption();
        this.gantt(this.chartData);
      }
    },
    nextDay(d = 0) {
      let day = dayjs(dayjs(this.searchParams.date).add(d, "day")).format(
        "YYYY-MM-DD"
      );
      if (dayjs().diff(day, "day") >= 0) {
        this.searchParams.date = day;
      }
    },
    getDictionaryBatch() {
      dictionaryBatch({
        codes: "vehicleType,CXvehicleType",
      }).then((res) => {
        let vehicleType = res.data.vehicleType.map((item) => {
          return item.dictValue;
        });
        let CXvehicleType = res.data.CXvehicleType.map((item) => {
          return item.dictValue;
        });
        let dicty = Array.from(new Set([...vehicleType, ...CXvehicleType]));
        this.dictList.deviceTypeList = dicty.map((item) => {
          return {
            dictKey: item,
            dictValue: item,
          };
        });
      });
      userProject({ userId: this.$store.state.userInfo.userId }).then((res) => {
        if (res.data.length > 0) {
          //this.projectList = res.data;
          this.dictList.projectList = res.data.map((item) => {
            return {
              dictKey: item.projectId,
              dictValue: item.projectName,
            };
          });
          //this.searchParams.projectId = res.data[0].projectId;
          this.getCustomerList();
        }
      });
    },
    getCustomerList() {
      this.dictList.customerList = [];
      this.searchParams.userId = "";
      getProjectCustomer({ projectId: this.searchParams.projectId }).then(
        (res) => {
          if (res.data.records) {
            this.dictList.customerList = res.data.records.map((item) => {
              return {
                dictKey: item.customerUserId,
                dictValue: item.customerName,
              };
            });
          } else {
            this.dictList.customerList = [];
          }
        }
      );
    },
    // setChartOption() {
    //   let that = this;
    //   var baseDate = `${new Date().getFullYear()}/${
    //     new Date().getMonth() + 1
    //   }/${new Date().getDate()}`;
    //   let ydata = [];
    //   let xdata = [];
    //   ydata = this.chartData.map((item, index) => {
    //     item.workHourDetails.forEach((work) => {
    //       xdata.push({
    //         value: [
    //           index,
    //           `${baseDate} ${dayjs(work.statusBegin || "00:00").format(
    //             "HH:mm"
    //           )}`,
    //           `${baseDate} ${dayjs(work.statusEnd || "00:00").format("HH:mm")}`,
    //           this.statuTypes[work.status].title || "",
    //           work.status,
    //         ],
    //       });
    //     });
    //     return [item.machineNo, item.deviceTypeName];
    //   });
    //   // 假数据填充使图表可以显示
    //   if (!xdata.length) {
    //     xdata = [
    //       {
    //         value: [0, `${baseDate} 00:00`, `${baseDate} 00:00`, ""], //0,1,2代表y轴的索引，后两位代表x轴数据开始和结束
    //       },
    //     ];
    //   }
    //   // echart配置
    //   this.chartOption = {
    //     color: "#0A8BFF",
    //     backgroundColor: "#fff",
    //     // title: {
    //     //   text: "工时统计甘特图",
    //     //   subtext: "不同日期的当日工时统计",
    //     // },
    //     tooltip: {
    //       enterable: true,
    //       //alwaysShowContent:true,
    //       hideDelay: 100,
    //       backgroundColor: "rgba(255,255,255,1)", //背景颜色（此时为默认色）
    //       borderRadius: 5, //边框圆角
    //       padding: [5, 0, 5, 0], // [5, 10, 15, 20] 内边距
    //       textStyle: {
    //         color: "#000",
    //       },
    //       // position: function (point, params, dom, rect, size) {
    //       //   $(dom).html(
    //       //     '<div style="font-size:14px;color:rgba(0,0,0,0.65)" class="tip"><h1 style="font-size:14px;color:rgba(0,0,0,0.85);height:25px;display:flex;align-items: center;padding:0 0 5px 8px;margin:0;border-bottom:1px solid rgba(0,0,0,0.06);font-weight:bold">' +
    //       //       params.name.split(",")[0] +
    //       //       '</h1> <p style="margin:0;padding:0 8px 0 8px;height:25px;display:flex;align-items: center;">时段:' +
    //       //       params.value[1].split(" ")[1] +
    //       //       "~" +
    //       //       params.value[2].split(" ")[1] +
    //       //       ' </p><p style="margin:0;padding:0 8px 0 8px;height:25px;display:flex;align-items: center;">总工时:' +
    //       //       params.name.split(",")[1] +
    //       //       " </p></div>"
    //       //   );
    //       // },
    //     },
    //     // legend: {
    //     //   //图例
    //     //   data: ["工时"],
    //     //   left: "90px",
    //     //   top: "6%",
    //     //   itemWidth: 16,
    //     //   itemHeight: 16,
    //     //   selectedMode: false, // 图例设为不可点击
    //     //   textStyle: {
    //     //     color: "rgba(0, 0, 0, 0.45)",
    //     //     fontSize: 14,
    //     //   },
    //     // },
    //     grid: {
    //       //绘图网格
    //       left: "0%",
    //       right: "3%",
    //       top: "10%",
    //       bottom: "10%",
    //       containLabel: true,
    //     },
    //     xAxis: {
    //       type: "time",
    //       position: "top",
    //       //interval: 1, //以一个小时递增
    //       splitNumber: 24,
    //       max: `${baseDate} 24:00`,
    //       min: `${baseDate} 00:00`, //将data里最小时间的整点时间设为min,否则min会以data里面的min为开始进行整点递增
    //       axisLabel: {
    //         formatter: function (value) {
    //           return "{H}";
    //         },
    //         show: true,
    //         textStyle: {
    //           color: "rgba(0,0,0,0.65)", //更改坐标轴文字颜色
    //           fontSize: 14, //更改坐标轴文字大小
    //         },
    //       },
    //       axisLine: {
    //         //show: true,
    //         lineStyle: {
    //           color: "rgba(0,0,0,0.1)",
    //         },
    //       },
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: "dashed",
    //         },
    //       },
    //     },
    //     yAxis: [
    //       {
    //         axisLine: {
    //           lineStyle: {
    //             color: "rgba(0,0,0,0.1)",
    //           },
    //         },
    //         scrollbar: {
    //           show: true,
    //         },
    //         inverse: true,
    //         interval: 1,
    //         data: ydata,
    //         axisLabel: {
    //           textStyle: {
    //             color: "rgba(0, 0, 0, 0.65)", //刻度颜色
    //             fontSize: 14, //刻度大小
    //           },
    //           formatter: function (value) {
    //             // console.log(value);
    //             let a = value.split(",")[0];
    //             let b = value.split(",")[1];
    //             return `{a|${a}}-{a|${b}}`;
    //           },
    //           rich: {
    //             a: {
    //               color: "#333333",
    //               fontSize: 14,
    //               fontWeight: 400,
    //             },
    //             b: {
    //               color: "#333333",
    //               fontSize: 14,
    //               fontWeight: 400,
    //             },
    //           },
    //         },
    //       },
    //     ],
    //     series: [
    //       // 用空bar来显示三个图例
    //       { name: "工时", type: "bar", data: [], barMaxWidth: 10 },
    //       // { name: state[1], type: "bar", data: [], barMaxWidth: 10 },
    //       // { name: state[2], type: 'bar', data: [],barMaxWidth:10 },
    //       {
    //         type: "custom",
    //         renderItem: function (params, api) {
    //           //开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
    //           var categoryIndex = api.value(0); //这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
    //           var start = api.coord([api.value(1), categoryIndex]); // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
    //           var end = api.coord([api.value(2), categoryIndex]);
    //           // var height = api.size([0, 1])[1];
    //           var height = 20;
    //           return {
    //             type: "rect", // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
    //             shape: echarts.graphic.clipRectByRect(
    //               {
    //                 // 矩形的位置和大小。
    //                 x: start[0],
    //                 y: start[1] - height / 2,
    //                 width: end[0] - start[0],
    //                 height: height,
    //               },
    //               {
    //                 // 当前坐标系的包围盒。
    //                 x: params.coordSys.x,
    //                 y: params.coordSys.y,
    //                 width: params.coordSys.width,
    //                 height: params.coordSys.height,
    //               }
    //             ),
    //             style: api.style(),
    //           };
    //         },
    //         encode: {
    //           x: [1, 2], // data 中『维度1』和『维度2』对应到 X 轴
    //           y: 0, // data 中『维度0』对应到 Y 轴
    //         },
    //         itemStyle: {
    //           normal: {
    //             color: function (params) {
    //               //console.log(params);
    //               // if (
    //               //   params.data !== null &&
    //               //   params.data["value"] &&
    //               //   params.data["value"][3] == "in"
    //               // ) {
    //               //   return "#3470FF";
    //               // } else {
    //               //   return "#FCB818";
    //               // }
    //               return (
    //                 that.statuTypes[params.data["value"][4]]?.color || "#cccccc"
    //               );
    //             },
    //           },
    //         },
    //         data: xdata,
    //       },
    //     ],
    //     dataZoom: [
    //       {
    //         type: "slider",
    //         yAxisIndex: [0],
    //         zoomLock: true,
    //         start: 0,
    //         end: 50,
    //       },
    //     ],
    //   };
    // },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "zdy-row";
      }
    },
    resetData() {
      this.searchParams = {
        index: 1,
        size: 10,
        total: 0,
        date: dayjs().format("YYYY-MM-DD"),
        machineNo: "",
        deviceTypeName: "",
        projectId: "",
        userId: "",
        ...this.$route.query,
      };
      this.getData();
    },
    changeProject(e) {
      //console.log(e);
      this.getCustomerList();
    },
    ganttChange(i) {
      this.statuTypes[i].showBar = !this.statuTypes[i].showBar;
      this.gantt(this.chartData);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  height: 100%;
  .navigation-bar {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .filter {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 16px 15px;
  }
  .table {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 12px;
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &-action {
      display: flex;
      justify-content: space-between;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .total {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.chart {
  width: 100%;
  flex: 1;
  max-height: 540px;
  overflow: auto;
}
.gantt {
  //position: relative;
}
.export {
  background: #f5f5f5;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 13px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
.point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.back-btn {
  padding: 7px 12px;
  background: #4278c9;
  border-radius: 17px 17px 17px 17px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.status-types {
  display: flex;
  align-items: center;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
</style>
